import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ActionBar, Panel, PageLoading, utils, Text, Hide,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useManageMatter } from 'state/ManageMatterProvider';
import Container from 'components/Container';
import UploadSection from 'screens/components/UploadSection';

const { useScrollToTop } = utils;

const UploadPage = () => {
  const { id } = useParams();
  const docId = Number(id);
  const { authToken } = useAuth();
  const { setScrollToTop } = useScrollToTop(true);
  const { state } = useManageMatter();

  useEffect(() => {
    setScrollToTop(true);
  }, [docId, setScrollToTop]);

  if (!state.matterProgress || !authToken || !docId) return <PageLoading />;

  const progress = state.matterProgress.uploadDocuments.find((p) => p.id === docId);
  const disabled = progress.documentStatus > 4;
  const requiresInput = !progress.completed;

  return (
    <Container center>
      <Panel mb="0">
        <UploadSection disabled={disabled} matter={state.matter} />
      </Panel>
      <ActionBar
        showSkip={false}
        showNext={false}
      >
        { requiresInput && <Text color="base60" fontSize="1"><Hide breakpoints={[0, 1]}>Upload a document or select an option to continue.</Hide></Text> }
      </ActionBar>
    </Container>
  );
};

export default UploadPage;
