import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';
import {
  Button, Panel, PageLoading, P, H4, OL, LI, ActionPanel, Flex, Text, Label, Radio, Row, Column, FieldError, ErrorSummary, utils, toast,
} from '@galilee/lilee';
import { setUploadRejectionReason, setUploadMultipleDocuments } from 'actions/Upload';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import { useUploadDoc } from 'state/UploadProvider';
import UploadedMultiDocuments from './UploadedDocument';

const { useScrollToTop } = utils;

const getColor = (p) => {
  if (p.isDragAccept) {
    return p.theme.colors.base40;
  }
  if (p.isDragReject) {
    return p.theme.colors.error;
  }
  if (p.isDragActive) {
    return p.theme.colors.base40;
  }
  return p.theme.colors.base20;
};

const UploadContainer = styled(Flex)`
  flex: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.space[7]};
  border-width: 2px;
  border-radius: ${(p) => p.theme.radii[3]};
  border-color: ${(p) => getColor(p)};
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out;
`;

const UploadSection = ({ disabled, matter, errors }) => {
  const documentUploadLimit = 3;
  const { id } = useParams();
  const docId = Number(id);
  const { authToken, support } = useAuth();
  const { isTenantSupport } = support;
  const { dispatch: matterDispatch } = useMatter();
  const { dispatch: applicationDispatch } = useApplication();
  const { state: uploadState, dispatch } = useUploadDoc();
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setUploading] = useState(false);
  const { setScrollToTop } = useScrollToTop(true);

  useEffect(() => {
    setUploadError(null);
    setScrollToTop(true);
  }, [docId, setScrollToTop]);

  const {
    name: uploadDocumentName, uploadDocumentRejectionReasons, uploadDocumentInstructions, uploadDocumentMeta,
  } = uploadState;

  const isQBEReferral = matter?.lender?.toLowerCase().includes('pepper') && uploadDocumentName.toLowerCase().includes('insurance');

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setUploadError(null);
    if (isUploading) return;

    if (rejectedFiles && rejectedFiles.length > 0) {
      setUploadError('Please upload files less than 10Mb that is a png, jpeg or pdf.');
      return;
    }

    const upload = async (uploadedDocuments) => {
      setUploading(true);
      try {
        await setUploadMultipleDocuments(dispatch, applicationDispatch, matterDispatch, docId, authToken, acceptedFiles, uploadedDocuments);
      } catch (error) {
        toast.error('We couldn\'t upload your documents, please reload the page and try again');
      } finally {
        setUploading(false);
      }
    };

    const uploadLimit = Object.keys(uploadDocumentMeta?.documentsUploaded || {}).length + Object.keys(acceptedFiles).length;
    const uploadedDocuments = uploadDocumentMeta?.documentsUploaded ?? {};

    if (uploadLimit <= documentUploadLimit) {
      upload(uploadedDocuments);
    } else {
      setUploadError(`Please upload ${documentUploadLimit} or less files.`);
    }
  }, [isUploading, uploadDocumentMeta?.documentsUploaded, dispatch, applicationDispatch, matterDispatch, docId, authToken]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: true, minSize: 0, maxSize: 10485760, accept: 'image/jpg, image/jpeg, image/png, application/pdf', onDrop,
  });

  if (!authToken || !docId) return <PageLoading />;

  const changeReason = async (reasonId) => {
    if (isUploading) return;

    try {
      setUploading(true);
      await setUploadRejectionReason(dispatch, applicationDispatch, matterDispatch, reasonId, docId, authToken);
    } catch (error) {
      toast.error('We couldn\'t select your rejection reason, please reload the page and try again');
    } finally {
      setUploading(false);
    }
  };

  const uploadLimit = documentUploadLimit <= Object.keys(uploadDocumentMeta?.documentsUploaded ?? 0).length;
  return (
    <>
      <Panel.Subtitle>Upload Documents</Panel.Subtitle>
      <Panel.Title pb={[0]}>{uploadState.name}</Panel.Title>

      {errors && <ErrorSummary errors={errors} title="There were errors" mb={[5, 6]} />}

      <Panel.Section>

        <H4 mb={[2, 3, 4, 5]}>Summary</H4>
        <P mb={[2, 3, 4, 5]}>{uploadState.summary}</P>
        {isQBEReferral && (
        <P mb={[5, 6, 7]}>
          If you don’t have insurance, click
          {' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.qbe.com/au/kwil?utm_source=app&utm_medium=campaignassets&utm_campaign=kwil-app-1124">here</a>
          {' '}
          to find out how QBE could assist you.
        </P>
        )}

        {uploadDocumentInstructions && uploadDocumentInstructions.length > 0 && (
          <>
            <H4 mb={[2, 3, 4, 5]}>{uploadState.instructionHeading}</H4>
            <OL mb={[5, 6, 7]} fontSize="1">
              { uploadDocumentInstructions.map((instruction) => <LI key={instruction.id}>{instruction.text}</LI>) }
            </OL>
          </>
        )}

        <ActionPanel p={[5, 6, 7, 8]} mb={0}>
          <Row collapseIndex={2} justifyContent="center">
            <Column mb="0" colspan="5" maxWidth="300px">
              {!uploadLimit
                && (
                <Uploader
                  RootProps={getRootProps({ isDragActive, isDragAccept, isDragReject })}
                  InputProps={getInputProps({ disabled })}
                  disabled={disabled || isTenantSupport}
                  limit={documentUploadLimit}
                />
                )}
              <UploadedMultiDocuments
                documentsMetaData={uploadDocumentMeta}
                dispatch={dispatch}
                applicationDispatch={applicationDispatch}
                matterDispatch={matterDispatch}
              />
              <FieldError error={uploadError} />
            </Column>
            {uploadDocumentRejectionReasons && uploadDocumentRejectionReasons.length > 0 && (
              <>
                <Column mb="0" alignItems="center">
                  <H4 py={[6, 4]} px={[5, null, 6, 7, 8]}>or</H4>
                </Column>
                <Column mb="0" colspan="5">
                  <Flex flexDirection="column" flex="5">
                    <Label>I will not be uploading this document because:</Label>
                    <Flex flexDirection="column" mt="6">
                      { uploadDocumentRejectionReasons && uploadDocumentRejectionReasons.map((item) => (
                        <Radio
                          name="rejectionReason"
                          disabled={disabled || isTenantSupport || isUploading}
                          key={item.id}
                          value={item.id}
                          label={item.text}
                          checked={item.chosen}
                          onChange={() => changeReason(item.id)}
                        />
                      ))}
                    </Flex>
                  </Flex>
                </Column>
              </>
            )}
          </Row>
        </ActionPanel>
        {isQBEReferral && (
        <P mt={[5, 6, 7]}>
          QBE Home Insurance, QBE Contents Insurance and QBE Landlord Insurance are issued by QBE Insurance (Australia) Limited
          (ABN 78 003 191 035, AFSL 239545) (QBE). Kwil is the name of the document management application owned and operated by
          Galilee Solicitors Pty Ltd (ABN 94 617 256 201) (Galilee). Galilee acts as a referrer and receives commission of 15% for
          each referral it makes that results in a policy being taken out with QBE. To decide if the relevant product is right for
          you, please read the relevant Product Disclosure Statement (PDS) and Target Market Determination (TMD) available at https://www.qbe.com/au.
        </P>
        )}
      </Panel.Section>
    </>
  );
};

const Uploader = ({
  RootProps, InputProps, disabled, limit,
}) => {
  if (disabled) {
    return (
      <UploadContainer>
        <Text fontSize="0" fontWeight="bold" mb="3">
          Drag and drop functionality is currently disabled for support stuff.
        </Text>
        <input {...InputProps} />
        <Text uppercase fontSize="0" fontWeight="bold" mb="3">
          Drag your pdf, png or jpg here
        </Text>
        <Text uppercase fontSize="0" fontWeight="bold" mb="3">or</Text>
        <Button color="secondary" disabled={disabled}>Browse</Button>
        <Text mt="3" muted fontSize={0}>10Mb maximum per file</Text>
        <Text mt="3" muted fontSize={0}>
          Upload up to
          {' '}
          {limit}
          {' '}
          files
        </Text>
      </UploadContainer>
    );
  }

  return (
    <UploadContainer {...RootProps}>
      <input {...InputProps} />
      <Text uppercase fontSize="0" fontWeight="bold" mb="3">
        Drag your pdf, png or jpg here
      </Text>
      <Text uppercase fontSize="0" fontWeight="bold" mb="3">or</Text>
      <Button color="secondary" disabled={disabled}>Browse</Button>
      <Text mt="3" muted fontSize={0}>10Mb maximum per file</Text>
      <Text mt="3" muted fontSize={0}>
        Upload up to
        {' '}
        {limit}
        {' '}
        files
      </Text>
    </UploadContainer>
  );
};

Uploader.propTypes = {
  RootProps: PropTypes.shape().isRequired,
  InputProps: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
};

UploadSection.defaultProps = {
  errors: null,
};

UploadSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  matter: PropTypes.shape().isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default UploadSection;
