import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ActionBar, Panel, PageLoading, utils, Text, Hide,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import Container from 'components/Container';
import PROGRESS_STAGES from 'enums/ProgressStages';
import { getPageMeta } from 'utils';
import UploadSection from 'screens/components/UploadSection';

const { useScrollToTop } = utils;

const UploadPage = () => {
  const { id } = useParams();
  const docId = Number(id);
  const history = useHistory();
  const { authToken } = useAuth();
  const { state } = useMatter();
  const { setScrollToTop } = useScrollToTop(true);

  useEffect(() => {
    setScrollToTop(true);
  }, [docId, setScrollToTop]);

  if (!state.matter || !state.matterProgress || !authToken || !docId) return <PageLoading />;

  const {
    isCompletedByAnyone, isNotEditable, isNotCompletedByMeOrSubmittedOrFinalised, nextRoute, currentStep,
  } = getPageMeta(state.matter, state.matterProgress, PROGRESS_STAGES.UPLOAD, docId);

  return (
    <Container>
      <Container center>
        <Panel mb="0">
          <UploadSection disabled={isNotEditable} matter={state.matter} errors={currentStep.errors} />
        </Panel>
        <ActionBar
          showNext={isCompletedByAnyone || isNotEditable}
          onNextClick={() => history.push(nextRoute)}
        >
          { isNotCompletedByMeOrSubmittedOrFinalised && <Text color="base60" fontSize="1"><Hide breakpoints={[0, 1]}>Upload a document or select an option to continue.</Hide></Text> }
        </ActionBar>
      </Container>
    </Container>
  );
};

export default UploadPage;
